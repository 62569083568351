import { FC } from "react";

import { useTranslation } from "localization";
import { ITextInput } from "types";
import { GLOBAL } from "constants/global";
import { getNumericNumbers } from "helpers";
import { cardNumberTyping } from "helpers/cardNumberTyping";

import searchIcon from "assets/images/search.svg";
import eyeIcon from "assets/images/inputs/eye.svg";
import eyeSlashIcon from "assets/images/inputs/eye-slash.png";
import dangerIcon from "assets/images/danger.svg";

export const TextInput: FC<ITextInput> = ({
  name,
  label,
  type = "text",
  isSearch,
  placeholder = "",
  isPassword,
  showPassword,
  handleShowPassword,
  register,
  required = true,
  errors,
  pattern,
  patternError,
  setValue,
  validate,
  disabled = false,
  isNumber,
  setFieldValue,
  isCardNumber,
  clearErrors,
}) => {
  const translation = useTranslation();

  const getErrorMessage = (): string | undefined => {
    const error = errors?.[name];

    if (error?.type === GLOBAL.required) {
      return translation.required;
    } else if (error?.type === GLOBAL.validate) {
      return translation.password_matches;
    } else if (error?.type === GLOBAL.custom) {
      return typeof error.message === "string" ? error.message : undefined;
    } else {
      return patternError;
    }
  };

  const errorMessage: string | undefined = getErrorMessage();
  return (
    <div
      className={`text_input_container ${!label ? "remove_gap" : ""} ${
        isSearch ? "search_input" : ""
      } ${disabled ? "disable_input_container" : ""}`}
    >
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      <div className="input_field">
        <div className="input_filed_content">
          <input
            {...register(name, {
              required,
              pattern,
              validate,
              onChange: (e) => {
                if (e.target.value !== " ") {
                  if (name === "phoneNumber") {
                    const value = e.target.value
                      .replace(/(?!^)[^0-9]/g, "")
                      .replace(/^[^+0-9]/, "");

                    return setValue(name, value);
                  } else if (isNumber) {
                    const value = getNumericNumbers(e.target.value);

                    if (isCardNumber) {
                      const formattedValue = cardNumberTyping(value);

                      if (clearErrors) clearErrors(name);

                      return setValue(name, formattedValue.trim());
                    }

                    if (setFieldValue) setFieldValue(value);

                    return setValue(name, value);
                  }
                  return setValue(name, e.target.value);
                }
                return setValue(name, "");
              },
            })}
            name={name}
            type={type}
            id={name}
            className={`text_input ${!!errors?.[name] ? "input_error" : ""} ${
              isSearch || isPassword ? "text_input_adornment" : ""
            } ${disabled ? "disabled_text_input" : ""}`}
            placeholder={placeholder}
            autoComplete="new-password"
            disabled={disabled}
          />
          {isSearch && (
            <img src={searchIcon} alt="Search" className="input_adornment" />
          )}
          {isPassword && (
            <img
              src={showPassword ? eyeSlashIcon : eyeIcon}
              alt="Password"
              className="input_adornment password_adornment"
              onClick={handleShowPassword}
            />
          )}
        </div>
        {!!errors?.[name] && (
          <div className="error_field">
            <img src={dangerIcon} alt="Danger" className="danger_icon" />
            <p className="error_message">{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};
