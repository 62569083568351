import { FC } from "react";

import { TableActions } from "./TableActions";
import { ITransactionTableHeader } from "types";
import { FORMAT_DATA } from "constants/global";
import { MainTab } from "components/tabs";

export const TableHeader: FC<ITransactionTableHeader> = ({
  openFilters,
  setOpenFilters,
  setActiveFormat,
  activeFormat,
  onTabChange,
}) => {
  return (
    <div className="table_header transactions_table_header">
      <MainTab
        data={FORMAT_DATA}
        setActiveTab={setActiveFormat}
        activeTab={activeFormat}
        customClass="trades_tabs"
        onTabChange={onTabChange}
      />
      <TableActions openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
