import { useTranslation } from "localization";

import { useAppDispatch } from "reduxState/store";
import { setShowAddToCardPopup } from "reduxState/features/main";
import { MainButton } from "components";

import noCardIcon from "assets/images/noCard.svg";

export const EmptyCardsPage = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const handleAddCard = () => {
    dispatch(setShowAddToCardPopup(true));
  };

  return (
    <div className="empty_cards_container">
      <div className="empty_cards_content">
        <div className="no_card_body">
          <img src={noCardIcon} alt="No Cards" className="no_cards_icon" />
          <div className="no_card_info">
            <h4 className="no_card_title">{translation.no_cards}</h4>
            <p className="no_card_description">{translation.no_cards_desc}</p>
          </div>
        </div>
        <MainButton
          name={translation.add_card}
          customClass="custom_button"
          onClick={handleAddCard}
        />
      </div>
    </div>
  );
};
