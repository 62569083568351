import { Dispatch, FC, SetStateAction, useState } from "react";

import reloadIcon from "assets/images/reload.svg";

interface RefreshButton {
  loading: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  refresh: boolean;
}

export const RefreshButton: FC<RefreshButton> = ({
  setRefresh,
  refresh,
  loading,
}) => {
  const [isReloadIcon, setIsReloadIcon] = useState<boolean>(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
    setIsReloadIcon(true);

    setTimeout(() => {
      setIsReloadIcon(false);
    }, 1000);
  };

  return (
    <div className="refresh_button">
      <img
        src={reloadIcon}
        alt="Reload"
        className={`${isReloadIcon || loading ? "reload_icon" : ""}`}
        onClick={handleRefresh}
      />
    </div>
  );
};
