import { useTranslation } from "localization";

import infoIcon from "assets/images/infoIcon.svg";

export const InfoSection = () => {
  const translation = useTranslation();

  return (
    <div className="info_section">
      <div className="image_section">
        <img src={infoIcon} alt="Info" />
      </div>
      <div className="text_section">
        <p className="title">{translation.card_name_text}</p>
      </div>
    </div>
  );
};
