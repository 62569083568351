import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Pagination } from "components";
import { DesktopTable } from "./desktoptable/DesktopTable";
import { MobileTable } from "./mobileTable/MobileTable";
import { GLOBAL, USER_TYPES } from "constants/global";
import { IMainTableElement, ITableFilters, IUserRequest } from "types";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getMerchantRequests, getUserTradeRequests } from "reduxState";
import { Chat } from "./chat";
import { REQUEST_DATA, TRADE_REQUEST_STATUSES } from "constants/trade";
import { EmptyTablePage } from "../../EmptyPage";
import { MOBILE_WINDOW_WIDTH } from "constants/global";
import { useTranslation } from "localization";

interface ITransactionTable {
  headerData: IMainTableElement[];
  filters: ITableFilters;
  activeFormat: string;
}

export const TableBody: FC<ITransactionTable> = ({
  headerData,
  filters,
  activeFormat,
}) => {
  const [selectedRequest, setSelectedRequest] =
    useState<IUserRequest>(REQUEST_DATA);
  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);

  const querySearch = new URLSearchParams(useLocation().search);
  const queryPage = querySearch.get(GLOBAL.page);
  const queryPerPage = querySearch.get(GLOBAL.per_page);

  const [page, setPage] = useState<number>(
    queryPage ? Number(queryPage) : GLOBAL.default_page
  );
  const [perPage, setPerPage] = useState<number>(
    queryPerPage ? Number(queryPerPage) : GLOBAL.default_per_page
  );

  const total = useAppSelector((state) => state.trades.userRequestsTotal);
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();

  const isMerchant = user?.role === USER_TYPES.merchant;

  const dispatch = useAppDispatch();

  const handleScrollToTop = () => {
    if (window.innerWidth > MOBILE_WINDOW_WIDTH) {
      window?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    } else {
      const accountSection = document
        .getElementById("profile_menu")
        ?.getBoundingClientRect();

      const mobileTableContainer = document.getElementById(
        "mobile_table_container"
      );

      const accountSectionHeight = accountSection?.height;

      window?.scrollTo({
        behavior: "smooth",
        top: accountSectionHeight,
      });

      mobileTableContainer?.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    const params = {
      filters: {
        statuses: [
          TRADE_REQUEST_STATUSES.rejected,
          TRADE_REQUEST_STATUSES.closed,
          TRADE_REQUEST_STATUSES.completed,
        ],
        format: activeFormat,
      },
      page: `${page}`,
      per_page: `${perPage}`,
      for_history: true,
    };
    isMerchant
      ? dispatch(getMerchantRequests({ params }))
      : dispatch(getUserTradeRequests({ params }));
  }, [page, perPage, activeFormat]);

  useEffect(() => {
    if (!openChatHistory) {
      document.body.style.overflow = "unset";
    }
  }, [openChatHistory]);

  return (
    <div className="table_body">
      {!!total ? (
        <>
          <DesktopTable
            headerData={headerData}
            setSelectedRequest={setSelectedRequest}
            setOpenChatHistory={setOpenChatHistory}
          />
          {openChatHistory && (
            <Chat
              requestData={selectedRequest}
              setOpenChatHistory={setOpenChatHistory}
            />
          )}
          <MobileTable
            setSelectedRequest={setSelectedRequest}
            setOpenChatHistory={setOpenChatHistory}
          />
        </>
      ) : (
        <EmptyTablePage
          filters={filters}
          message={translation.no_transactions_found}
        />
      )}

      <div className="table_pagination">
        <Pagination
          perPage={perPage}
          total={total}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          handleAction={handleScrollToTop}
        />
      </div>
    </div>
  );
};
