import { useRef } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import validator from "validator";

import { ModalContainer } from "../ModalAuth";
import { InfoSection } from "./InfoSection";

import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { TextInput } from "components";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowAddToCardPopup } from "reduxState/features/main";
import { CARD_PLACEHOLDER } from "constants/profile";
import { GLOBAL, MIN_MAX_CHARACTERS } from "constants/global";
import { createCard } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";
import cardIcon from "assets/images/cardPopup.svg";

export const AddCardModal = () => {
  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.cardsData.loading);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    dispatch(setShowAddToCardPopup(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const cardNumber = validator.isCreditCard(data.cardNumber);
    if (!cardNumber) {
      setError("cardNumber", {
        type: GLOBAL.custom,
        message: translation.enter_valid_card,
      });
      return;
    }

    const withoutSpacesCardNumber = data.cardNumber.replace(/\s+/g, "");

    const validData = { ...data, cardNumber: withoutSpacesCardNumber };

    dispatch(createCard({ data: validData, navigate: handleClose })).finally(
      () => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      }
    );
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal change_password_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={cardIcon} alt="Add card" className="logout_image" />
            <p className="logout_title">{translation.add_card}</p>
          </div>
        </div>
        <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="cardNumber"
            label={translation.card_number}
            register={register}
            errors={errors}
            setValue={setValue}
            isNumber
            placeholder={CARD_PLACEHOLDER}
            isCardNumber
          />
          <div className="input_with_info">
            <TextInput
              name="cardHolder"
              label={translation.card_name}
              register={register}
              errors={errors}
              setValue={setValue}
              placeholder={translation.name_surname}
              pattern={MIN_MAX_CHARACTERS}
              patternError={translation.min_max_character_count}
            />
            {!errors.cardHolder && <InfoSection />}
          </div>
          <div className="logout_actions">
            <MainButton
              name={translation.add}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
