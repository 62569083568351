import {
  ChangeEvent,
  ClipboardEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { useTranslation } from "localization";
import {
  AUTH_CODE_LENGTH,
  KEY_NAMES,
  VERIFICATION_CODE_INPUTS,
} from "constants/global";
import { ModalContainer } from "../ModalAuth";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { disableTwoFa, enableTwoFa, verifyTwoFa } from "reduxState";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import useOutsideClick from "hooks/useOutsideClick";
import { setShowTwoFactor, setTwoFaData } from "reduxState/features/auth";
import { TOAST_MESSAGES, TOAST_TYPES } from "constants/toast";
import { ClipSpinner } from "components/spinners";
import { MainButton } from "components/buttons";

import closeIcon from "assets/images/close.svg";
import copyIcon from "assets/images/copy.svg";

export const TwoFactorModal = () => {
  const [code, setCode] = useState<string>("");
  const [isKeyboardOpen, setIsKeyBoardOpen] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const twoFactorData = useAppSelector((state) => state.auth.twoFaData);
  const loading = useAppSelector((state) => state.auth.twoFaLoading);
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: TOAST_MESSAGES.copy_success,
          open: true,
        })
      );
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    } catch (err) {
      return err;
    }

    document.body.removeChild(textArea);
  }

  const handleCopyHashCode = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(twoFactorData?.secret || "");

        dispatch(
          setToastData({
            type: TOAST_TYPES.success,
            message: TOAST_MESSAGES.copy_success,
            open: true,
          })
        );
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      } catch (error) {
        return error;
      }
    } else {
      fallbackCopyTextToClipboard(twoFactorData?.secret || "");
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    if (/[^0-9]/.test(input.value)) {
      input.value = "";
      return;
    }

    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    const newCode = code.split("");
    newCode[index] = input.value;

    setCode(newCode.join(""));

    input.select();

    if (input.value === "") {
      if (previousInput && previousInput.current) {
        previousInput.current.focus();
      }
    } else if (nextInput && nextInput.current) {
      nextInput.current.select();
    }
  };

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.target as HTMLInputElement;

    const previousInput = inputRefs[index - 1];

    if (
      (e.key === KEY_NAMES.backspace || e.key === KEY_NAMES.delete) &&
      input.value === ""
    ) {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1)
      );
      if (previousInput && previousInput.current) {
        previousInput.current.focus();
      }
    }
    setIsKeyBoardOpen(true);
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData("text");
    if (/^[0-9]+$/.test(pastedCode) && pastedCode.length === AUTH_CODE_LENGTH) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        if (inputRef.current !== null) {
          inputRef.current.value = pastedCode.charAt(index);
        }
      });
    }
  };

  const onClose = () => {
    dispatch(setTwoFaData({}));
    dispatch(setShowTwoFactor(false));
  };

  const handleVerify = (e: FormEvent) => {
    e.preventDefault();
    if (!!user?.id) {
      const id = `${user.id}`;
      setButtonLoading(true);

      if (user?.isEnabled2FA) {
        dispatch(
          disableTwoFa({ id, data: { token: code }, navigate: onClose })
        ).finally(() => {
          setButtonLoading(false);
          setTimeout(() => {
            dispatch(setOpenToast(false));
          }, 3000);
        });
      } else {
        dispatch(
          verifyTwoFa({ data: { token: code }, id, navigate: onClose })
        ).finally(() => {
          setButtonLoading(false);
          setTimeout(() => {
            dispatch(setOpenToast(false));
          }, 3000);
        });
      }
    }
  };

  useEffect(() => {
    if (user?.id && !user?.isEnabled2FA) {
      const id = `${user.id}`;
      dispatch(enableTwoFa({ id }));
    }
  }, [user]);

  useOutsideClick({ ref, handler: onClose });

  return (
    <ModalContainer>
      {loading ? (
        <ClipSpinner loading={loading} />
      ) : (
        <form onSubmit={handleVerify} className="two_factor_form">
          <div
            className="two_factor_modal"
            ref={ref}
            onClick={() => setIsKeyBoardOpen(false)}
          >
            <div className="modal_header">
              <div className="modal_description">
                <div className="close_modal">
                  <img src={closeIcon} alt="Close" onClick={onClose} />
                </div>
                <h2 className="two_factor_title">
                  {translation.two_factor_auth}
                </h2>
                <p className="two_factor_desc">
                  {user?.isEnabled2FA
                    ? translation.two_factor_disable_message
                    : translation.two_factor_auth_desc}
                </p>
              </div>
              {!user?.isEnabled2FA && (
                <div className="qr_code_field">
                  <img
                    src={twoFactorData?.qrCodeUrl}
                    alt="QR Code"
                    className="qr_code"
                  />
                </div>
              )}
            </div>
            {!user?.isEnabled2FA && (
              <div className="hand_write_section">
                <div className="code_copy_description">
                  <h4 className="cant_scan">{translation.cant_scan_qr}</h4>
                  <p className="cant_scan_desc">{translation.qr_scan_desc}</p>
                </div>
                <div className="qr_code_hash_field">
                  <p className="hash_code">{twoFactorData?.secret}</p>
                  <img
                    src={copyIcon}
                    alt="Copy"
                    className="copy_icon"
                    onClick={handleCopyHashCode}
                  />
                </div>
              </div>
            )}
            <div className="verification_field">
              <h3 className="enter_verification">
                {translation.enter_verification_code}
              </h3>
              <div className="verify_inputs">
                {VERIFICATION_CODE_INPUTS.slice(0, 3).map((index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className={`verify_input ${
                      isKeyboardOpen ? "verify_input_open" : ""
                    }`}
                  />
                ))}
                <div className="verify_line"></div>
                {VERIFICATION_CODE_INPUTS.slice(3, 6).map((index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className={`verify_input ${
                      isKeyboardOpen ? "verify_input_open" : ""
                    }`}
                  />
                ))}
              </div>
            </div>
            <div className="button_activate">
              <MainButton
                customClass="custom_button submit_button width_100"
                name={
                  user?.isEnabled2FA
                    ? translation.inactivate
                    : translation.activate
                }
                disabled={code.length !== AUTH_CODE_LENGTH || buttonLoading}
                type="submit"
                loading={buttonLoading}
              />
            </div>
          </div>
        </form>
      )}
    </ModalContainer>
  );
};
