import { useRef, useState } from "react";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import {
  setSelectedCardId,
  setShowDeleteCardModal,
} from "reduxState/features/main";
import useOutsideClick from "hooks/useOutsideClick";
import { deleteCard } from "reduxState";

import closeIcon from "assets/images/close.svg";
import deleteIcon from "assets/images/deleteModal.svg";
import { setOpenToast } from "reduxState/features/toast";

export const DeleteCardModal = () => {
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const selectedId = useAppSelector((state) => state.main.selectedCardId);
  const loading = useAppSelector((state) => state.cardsData.deleteLoading);

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(setShowDeleteCardModal(false));
  };

  const handleDeleteCard = () => {
    dispatch(
      deleteCard({
        id: Number(selectedId),
        navigate: handleClose,
        setDisableConfirm,
      })
    ).finally(() => {
      dispatch(setSelectedCardId(null));
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={deleteIcon} alt="Logout" className="logout_image" />
            <p className="logout_title">{translation.delete_card}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">{translation.delete_card_text}</p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.delete}
            customClass="custom_button width_100"
            onClick={handleDeleteCard}
            disabled={loading || disableConfirm}
            loading={loading}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleClose}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
