import { IAuthSlice } from "types";
import { createSlice } from "@reduxjs/toolkit";

import {
  getUser,
  enableTwoFa,
  changePassword,
  requestForMerchant,
  createRequest,
  forgotPassword,
  signUp,
} from "reduxState";

const initialState: IAuthSlice = {
  user: null,
  userLoading: false,
  twoFaData: null,
  twoFaLoading: false,
  showTwoFactor: false,
  changePasswordLoading: false,
  becomeMerchantLoading: false,
  forgotPasswordLoading: false,
  signUpLoading: false,
};

export const authSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    setShowTwoFactor: (state, { payload }) => {
      state.showTwoFactor = payload;
    },
    setTwoFaData: (state, { payload }) => {
      state.twoFaData = payload;
    },
    setUserLoading: (state, { payload }) => {
      state.userLoading = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.userLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.userLoading = false;
      });
    builder
      .addCase(enableTwoFa.pending, (state) => {
        state.twoFaLoading = true;
      })
      .addCase(enableTwoFa.fulfilled, (state, { payload }) => {
        state.twoFaData = payload?.data;
        state.twoFaLoading = false;
      })
      .addCase(enableTwoFa.rejected, (state) => {
        state.twoFaLoading = false;
      });
    builder
      .addCase(changePassword.pending, (state) => {
        state.changePasswordLoading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.changePasswordLoading = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.changePasswordLoading = false;
      });
    builder
      .addCase(requestForMerchant.pending, (state) => {
        state.becomeMerchantLoading = true;
      })
      .addCase(requestForMerchant.fulfilled, (state) => {
        state.becomeMerchantLoading = false;
      })
      .addCase(requestForMerchant.rejected, (state) => {
        state.becomeMerchantLoading = false;
      });
    builder
      .addCase(createRequest.pending, (state) => {
        state.becomeMerchantLoading = true;
      })
      .addCase(createRequest.fulfilled, (state) => {
        state.becomeMerchantLoading = false;
      })
      .addCase(createRequest.rejected, (state) => {
        state.becomeMerchantLoading = false;
      });
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPasswordLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPasswordLoading = false;
      });
    builder
      .addCase(signUp.pending, (state) => {
        state.signUpLoading = true;
      })
      .addCase(signUp.fulfilled, (state) => {
        state.signUpLoading = false;
      })
      .addCase(signUp.rejected, (state) => {
        state.signUpLoading = false;
      });
  },
});

export const { setShowTwoFactor, setTwoFaData, setUserLoading } =
  authSlice.actions;

export default authSlice.reducer;
