import { FC, useState } from "react";

import { IFilterInput } from "types";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const DateInput: FC<IFilterInput> = ({
  name,
  value,
  setValue,
  placeholder,
  isNumber,
  setFilters,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  return (
    <div className="text_input_container">
      <div className="input_field">
        <div className="input_filed_content">
          <DatePicker
            selectsRange={true}
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            onChange={(update) => {
              setDateRange(update);
              if (update[0] && update[1]) {
                setFilters((prev) => ({
                  ...prev,
                  startDate: update[0]?.valueOf(),
                  endDate: update[1]?.valueOf(),
                }));
              }

              if (!update[0] && !update[1]) {
                setFilters((prev) => ({
                  ...prev,
                  startDate: null,
                  endDate: null,
                }));
              }
            }}
            isClearable
            placeholderText={placeholder}
            maxDate={new Date()}
            customInput={
              <input
                name={name}
                id={name}
                className={`text_input date_custom_input`}
                placeholder={placeholder}
                autoComplete="new-password"
                value={value}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
