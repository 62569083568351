import { useEffect } from "react";

import { useTranslation } from "localization";
import { EmptyCardsPage } from "./EmptyCardsPage";
import { CardsSection } from "./CardsSection";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getCards } from "reduxState";
import { PageLoading } from "components/spinners";

export const CreditCardsComponent = () => {
  const total = true;

  const cards = useAppSelector((state) => state.cardsData.cards);
  const loading = useAppSelector((state) => state.cardsData.loading);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCards());
  }, []);

  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2 className="profile_element_title">{translation.my_cards}</h2>
      </div>
      <div
        className={`profile_cards_container ${
          total ? "" : "profile_cards_container_empty"
        }`}
      >
        {loading ? (
          <PageLoading loading={loading} customClass="table_loading" />
        ) : cards?.length ? (
          <CardsSection />
        ) : (
          <EmptyCardsPage />
        )}
      </div>
    </div>
  );
};
