import { useTranslation } from "localization";
import {
  PassportImageInput,
  TextInput,
  MainButton,
  PassportModal,
} from "components";
import { ACCOUNT_DISABLED_FIELDS, ACCOUNT_INPUTS } from "constants/profile";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { useEffect, useState } from "react";
import { IUser } from "types";
import { updateAccountData, updateAccountFormData } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { USER_TYPES } from "constants/global";
import { InfoSection } from "components/forms/forMerchants/InfoSection";

export const UserInfo = () => {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const user = useAppSelector((state) => state.auth.user);
  const isBasicUser = user?.role === USER_TYPES.user;

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const inputsData =
    user?.role === USER_TYPES.merchant
      ? ACCOUNT_INPUTS.filter((elem) => !elem.hideForMerchant)
      : ACCOUNT_INPUTS.filter((elem) => !elem.isMerchant);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const formData = new FormData();

    const changedData: { [key: string]: string } = {};
    let hasChange: boolean = false;

    for (let key in data) {
      const userKey = key as keyof IUser;
      if (data[key] !== user?.[userKey]) {
        hasChange = true;
      }
      if (!ACCOUNT_DISABLED_FIELDS.includes(key)) {
        changedData[key] = data[key];
        formData.append(key, data[key]);
      }
    }

    if (uploadedFiles?.length) hasChange = true;

    uploadedFiles?.forEach((elem, index) => {
      formData.append(`passportImage[${index}]`, elem);
    });

    const requestData = Object.fromEntries(formData);

    hasChange &&
      dispatch(
        updateAccountFormData({ data: requestData, id: `${user?.id}` })
      ).finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      });
  };

  const handleOpenCloseModal = () => {
    setShowExample(!showExample);
  };

  useEffect(() => {
    if (!showExample) {
      document.body.style.overflow = "unset";
    }
  }, [showExample]);

  useEffect(() => {
    if (user) {
      ACCOUNT_INPUTS.forEach((element) => {
        const key = element.name as keyof IUser;
        setValue(element.name, user?.[key], { shouldDirty: false });
      });
    }
  }, [user]);

  useEffect(() => {
    const data = watch();

    if (user) {
      for (let key in data) {
        if (data[key] !== user[key as keyof IUser]) {
          setDisableButton(false);
          break;
        }
        setDisableButton(true);
      }

      if (uploadedFiles?.length) {
        setDisableButton(false);
      }
    }
  }, [watch()]);

  return (
    <div className="account_element_field">
      {showExample && <PassportModal onClose={handleOpenCloseModal} />}
      <form className="user_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="inputs_field">
          {inputsData.map((field) => (
            <TextInput
              name={field.name}
              label={translation[field.label]}
              type={field.type || "text"}
              register={register}
              errors={errors}
              required={field.isRequired}
              setValue={setValue}
              disabled={field.disable}
              key={field.name}
              isNumber={field.isNumber}
            />
          ))}
        </div>
        {isBasicUser && (
          <div className="inputs_field">
            <div className="input_wrapper">
              <div className="image_input">
                <PassportImageInput
                  name="passportImage"
                  label={translation.passport_image_without_mandatory}
                  type="file"
                  setShowExample={setShowExample}
                  setUploadedFiles={setUploadedFiles}
                  uploadedFiles={uploadedFiles}
                  passportErrors={false}
                  disabled={!!user?.passportImage?.length}
                  passportImages={user?.passportImage ?? []}
                />
                {!user?.passportImage?.length && <InfoSection />}
              </div>
            </div>
          </div>
        )}
        <MainButton
          name={translation.save_changes}
          customClass="custom_button width_fit"
          type="submit"
          disabled={disableButton}
        />
      </form>
    </div>
  );
};
