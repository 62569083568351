import { IAuth } from "types";
import instance from "../baseRequest";

export const cardsApi = {
  getCards: async () => await instance.get("/v1/users/cards"),
  getCardById: async (id: number) =>
    await instance.get(`/v1/users/cards/${id}`),
  createCard: async (body: IAuth) =>
    await instance.post("/v1/users/cards", body),
  updateCard: async (id: number, body: IAuth) =>
    await instance.put(`/users/cards${id}`, body),
  deleteCard: async (id: number) =>
    await instance.delete(`/v1/users/cards/${id}`),
};
