import { FC, MouseEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Label, MainButton } from "components";
import { useTranslation } from "localization";
import { useAppDispatch } from "reduxState/store";
import { setShowActivate, setShowCloseTrade } from "reduxState/features/main";
import { CHANGE_TRADE_STATUS, TRADE_STATUSES } from "constants/trade";
import { ITrades } from "types";
import { GLOBAL } from "constants/global";
import { setMerchantRequests } from "reduxState/features/trades";

import closeIcon from "assets/images/close.svg";
import activateIcon from "assets/images/trades/activate.svg";
import inactivateIcon from "assets/images/trades/inactivate.svg";
import amdIcon from "assets/images/armenianCurrency.svg";

interface ITradeItem {
  element: ITrades;
  activeTab: string;
  activeFormat: string;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const TradeItem: FC<ITradeItem> = ({
  element,
  activeTab,
  activeFormat,
}) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const queryId = query.get(GLOBAL.id);

  const handleOpenPopup = () => {
    dispatch(
      setShowActivate({
        isOpen: true,
        id: element.id,
        type: CHANGE_TRADE_STATUS[element.status],
        tab: activeTab,
        format: activeFormat,
      })
    );
  };

  const handleCloseTrade = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(
      setShowCloseTrade({
        isOpen: true,
        id: element.id,
        tab: activeTab,
        format: activeFormat,
      })
    );
  };

  const handleOpenRequests = () => {
    const id = query.get(GLOBAL.id);
    dispatch(setMerchantRequests([]));
    if (id !== `${element.id}`) {
      query.set(GLOBAL.id, `${element.id}`);
      query.delete(GLOBAL.request_id);
      navigate(`?${query.toString()}`);
    } else {
      query.delete(GLOBAL.id);
      query.delete(GLOBAL.request_id);
      navigate(`?${query.toString()}`);
    }
  };

  return (
    <>
      <div
        className={`trade_item ${
          queryId === `${element.id}` ? "selected_trade_id" : ""
        }`}
        onClick={handleOpenRequests}
      >
        <div className="item_header">
          <div className="item_name_type">
            <p className="trade_id">{`${translation.trade_id} ${element.id}`}</p>
            <Label
              text={element.type}
              type={element.type === "sell" ? "red" : "green"}
            />
          </div>
          <div className="header_action">
            {!!element.requestCount && (
              <div className="count_section">{element.requestCount}</div>
            )}
            <div className="close_trade">
              <img
                src={closeIcon}
                alt="Close"
                className="close_icon"
                onClick={handleCloseTrade}
              />
            </div>
          </div>
        </div>
        <div className="item_body">
          <div className="body_element_field">
            <h5 className="element_title">{translation.currency}</h5>
            <div className="element_info_field">
              <img
                src={`${imageUrl}${element.currencyIcon}`}
                alt="Currency"
                className="currency_icon"
                crossOrigin="anonymous"
              />
              <div className="currency_element_field">
                <p className="element_info">{element.currencyTitle}</p>
                <span className="currency_line"></span>
                <p className="element_info">{element.currencyLongTitle}</p>
              </div>
            </div>
          </div>
          <div className="body_element_field">
            <h5 className="element_title">{translation.crypto_coin}</h5>
            <div className="element_info_field">
              <p className="element_info">{element.amount}</p>
            </div>
          </div>
          <div className="body_element_field">
            <h5 className="element_title">{translation.usd_to_amd}</h5>
            <div className="element_info_field">
              <div className="price_rate_section">
                <p className="element_info">{element.priceRate}</p>
                <img
                  src={amdIcon}
                  alt="Armenian Valuta"
                  className="rate_icon"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="item_footer" onClick={(e) => e.stopPropagation()}>
          <MainButton
            name={
              element.status === TRADE_STATUSES.active
                ? translation.inactivate
                : translation.activate
            }
            icon={
              element.status === TRADE_STATUSES.active
                ? inactivateIcon
                : activateIcon
            }
            iconClass="button_icon"
            customClass="log_in_button"
            onClick={handleOpenPopup}
          />
        </div>
      </div>
    </>
  );
};
