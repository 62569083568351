import { createSlice } from "@reduxjs/toolkit";

import { ICardsSlice } from "types";
import { getCards, createCard, getCardById, deleteCard } from "reduxState";

const initialState: ICardsSlice = {
  cards: [],
  loading: false,
  total: null,
  cardById: {
    id: 0,
    cardHolder: "",
    cardNumber: "",
    image: "",
    isDefault: false,
  },
  cardIdLoading: false,
  deleteLoading: false,
};

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCards.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cards = payload?.data?.data;
      })
      .addCase(getCards.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(createCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCard.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCard.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getCardById.pending, (state) => {
        state.cardIdLoading = true;
      })
      .addCase(getCardById.fulfilled, (state, { payload }) => {
        state.cardIdLoading = false;
        state.cardById = payload?.data?.data;
      })
      .addCase(getCardById.rejected, (state) => {
        state.cardIdLoading = false;
      });
    builder
      .addCase(deleteCard.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteCard.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteCard.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default cardsSlice.reducer;
