// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/SpaceGrotesk-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/SpaceGrotesk-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/fonts/SpaceGrotesk-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../public/fonts/SpaceGrotesk-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../public/fonts/SpaceGrotesk-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: lightFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: regularFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: mediumFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: semiBoldFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: boldFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

body {
  margin: 0;
  font-family: regularFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060b11;
}

* {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,4CAAkD;AACpD;;AAEA;EACE,wBAAwB;EACxB,4CAAoD;AACtD;;AAEA;EACE,uBAAuB;EACvB,4CAAmD;AACrD;;AAEA;EACE,yBAAyB;EACzB,4CAAqD;AACvD;;AAEA;EACE,qBAAqB;EACrB,4CAAiD;AACnD;;AAEA;EACE,SAAS;EACT,wBAAwB;EACxB,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["@font-face {\n  font-family: lightFont;\n  src: url(\"../public/fonts/SpaceGrotesk-Light.ttf\");\n}\n\n@font-face {\n  font-family: regularFont;\n  src: url(\"../public/fonts/SpaceGrotesk-Regular.ttf\");\n}\n\n@font-face {\n  font-family: mediumFont;\n  src: url(\"../public/fonts/SpaceGrotesk-Medium.ttf\");\n}\n\n@font-face {\n  font-family: semiBoldFont;\n  src: url(\"../public/fonts/SpaceGrotesk-SemiBold.ttf\");\n}\n\n@font-face {\n  font-family: boldFont;\n  src: url(\"../public/fonts/SpaceGrotesk-Bold.ttf\");\n}\n\nbody {\n  margin: 0;\n  font-family: regularFont;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #060b11;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
