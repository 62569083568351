import { TransactionTable } from "components";
import { useTranslation } from "localization";

export const UserTransactions = () => {
  const translation = useTranslation();

  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2 className="profile_element_title">
          {translation.transaction_history}
        </h2>
      </div>
      <div className="transaction_container">
        <TransactionTable />
      </div>
    </div>
  );
};
