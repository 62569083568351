import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LANGUAGE } from "constants/header";
import { ResetForm } from "components";
import { GLOBAL } from "constants/global";

export const ResetPassword = () => {
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const language = query.get(LANGUAGE);

  useEffect(() => {
    if (language) {
      localStorage.setItem(LANGUAGE, language);
      query.delete(LANGUAGE);
      navigate(`?${query.toString()}`);
    }
  }, [language]);

  useEffect(() => {
    localStorage.removeItem(GLOBAL.access_token);
  }, []);

  return (
    <div className="sign_up_container">
      <ResetForm />
    </div>
  );
};
