import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "localization";
import { RequestElement } from "./RequestElement";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getMerchantTradeRequests, merchantTradeRequests } from "reduxState";
import { GLOBAL } from "constants/global";
import { Chat } from "../../chat";
import { socket } from "socket";
import { ITradeRequest } from "types";
import { PageLoading } from "components/spinners";

interface ITradeRequestPage {
  activeTab: string;
}

export const TradeRequest: FC<ITradeRequestPage> = ({ activeTab }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [socketTradeRequests, setSocketTradeRequests] = useState<
    ITradeRequest[]
  >([]);

  const data = useAppSelector((state) => state.trades.merchantRequests);
  const loading = useAppSelector(
    (state) => state.trades.merchantRequestLoading
  );

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const query = new URLSearchParams(useLocation().search);
  const tradeId = query.get(GLOBAL.id);
  const requestId = query.get(GLOBAL.request_id);

  useEffect(() => {
    if (tradeId) {
      dispatch(merchantTradeRequests({ id: Number(tradeId) }));
      const handleTradeRequest = (data: ITradeRequest) => {
        setSocketTradeRequests((prev) => [data, ...prev]);
      };
      socket.emit("joinRoom", tradeId);
      socket.on("emitTradeRequest", handleTradeRequest);

      return () => {
        setSocketTradeRequests([]);
        socket.off("emitTradeRequest", handleTradeRequest);
      };
    }
  }, [tradeId]);

  useEffect(() => {
    setIsLoaded(false);
    if (requestId)
      dispatch(getMerchantTradeRequests({ id: Number(requestId) })).then(() => {
        setIsLoaded(true);
      });
  }, [requestId]);

  return (
    <div
      className={`ongoing_trades_list ${
        tradeId ? "" : "hide_requests_mobile"
      } ${requestId ? "open_chat_container" : ""}`}
    >
      {requestId && isLoaded ? (
        <Chat />
      ) : loading ? (
        <PageLoading loading={loading} />
      ) : tradeId ? (
        <div className="trades_request_list">
          <div className="trade_request_title">
            <span>{translation.trade}</span>{" "}
            <span className="trade_id_field">{`${translation.id} ${query.get(
              GLOBAL.id
            )}`}</span>{" "}
            <span>{translation.requests}</span>
          </div>
          {!!data?.length || socketTradeRequests?.length ? (
            <div className="trades_request">
              {[...socketTradeRequests, ...data].map((element) => (
                <RequestElement
                  element={element}
                  key={element.id}
                  activeTab={activeTab}
                  setSocketTradeRequests={setSocketTradeRequests}
                />
              ))}
            </div>
          ) : (
            <div className="empty_requests">
              <h3 className="select_trade">{translation.no_requests}</h3>
              <p className="select_trade_text">
                {translation.no_available_requests}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="empty_requests">
          <h3 className="select_trade">{translation.select_trade}</h3>
          <p className="select_trade_text">{translation.select_trade_text}</p>
        </div>
      )}
    </div>
  );
};
