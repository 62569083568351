export const GLOBAL = {
  required: "required",
  validate: "validate",
  access_token: "access_token",
  token: "token",
  verify_token: "verify_token",
  email: "email",
  id: "id",
  default_page: 1,
  default_per_page: 10,
  page: "page",
  per_page: "perPage",
  request_id: "requestId",
  wallet_id: "walletId",
  custom: "custom",
  format: "format",
};

export const INFO_LIST = [
  {
    id: 1,
    text: "passport_image_info",
    list: [],
  },
  {
    id: 2,
    text: "passport_image_info_step_one",
    list: [],
  },
  {
    id: 3,
    text: "passport_image_info_step_two",
    list: [],
  },
  {
    id: 4,
    text: "",
    list: [
      {
        id: 5,
        title: "max_size",
        text: "five_megabit",
      },
      {
        id: 6,
        title: "format",
        text: "format_types",
      },
    ],
  },
];

export const WITHOUT_FOOTER_PATHNAMES = [
  "/sign-up",
  "/sign-in",
  "/reset-password",
  "/set-password",
  "/complete-registration",
  "/ongoing-trades",
  "/forgot-password",
];

export const PATHNAMES = {
  sign_in: "/sign-in",
  sign_up: "/sign-up",
  home: "/",
  withdraw: "/profile/withdrawal",
  wallet: "/profile/wallet",
  forgot_password: "/forgot-password",
  account: "/profile/account",
};

export const USER_TYPES = {
  merchant: "merchant",
  user: "user",
  guest: "guest",
};

export const VERIFICATION_CODE_INPUTS = [0, 1, 2, 3, 4, 5];
export const STAR_ICONS_COUNT = [1, 2, 3, 4, 5];

export const KEY_NAMES = {
  backspace: "Backspace",
  delete: "Delete",
  enter: "Enter",
};

export const PASSWORD_REGEX: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,20}$/;
export const EMAIL_REGEX: RegExp =
  /^(?!.*[._+-]{2})([a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const NUMBER_REGEX: RegExp = /[^0-9]/g;
export const NUMBER_DOT_REGEX: RegExp = /[^0-9.]/g;
export const ONE_DOT_REGEX: RegExp = /(\..*)\./g;
export const ENGLISH_AND_NUMERIC_REGEX = /^[a-z0-9]*$/i;
export const MIN_MAX_CHARACTERS = /^.{2,50}$/;
export const LOCATION_PATTERN =
  /^[a-zA-Z0-9\s,.\-\/#&'éÉáÁíÍóÓúÚñÑçÇöÖäÄüÜàÀèÈ]{2,50}$/;

export const AUTH_CODE_LENGTH = 6;
export const PER_PAGE = 15;

export const MOBILE_WINDOW_WIDTH = 768;
export const TABLET_WINDOW_WIDTH = 1024;

export const CHAT_SECTION_GAP = 4;
export const CHAT_POPUP_SECTION_GAP = 76;

export const MIN_AMOUNT = 0.000001;

export const COIN_USD_NAMES = {
  usd: "usd",
  amount: "amount",
};

export const STATISTICS_DATA = {
  customers: "1500+",
  platform_uptime: "90%",
  market: "1",
  sup: "st",
};

export const FORMAT_DATA = [
  {
    id: 1,
    title: "online",
    value: "online",
  },
  {
    id: 2,
    title: "offline",
    value: "offline",
  },
];
