import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { useAppDispatch } from "reduxState/store";
import {
  setSelectedCardId,
  setShowDeleteCardModal,
} from "reduxState/features/main";
import { maskCardNumber } from "helpers/cardNumberTyping";

import deleteIcon from "assets/images/delete.svg";
import deleteActiveIcon from "assets/images/deleteActive.svg";
import checkedIcon from "assets/images/checked.svg";
import unCheckedIcon from "assets/images/unChecked.svg";

interface ICard {
  cardHolder: string;
  cardNumber: string;
  icon: string;
  id: number;
  isHaveCheckMark?: boolean;
  isChecked?: boolean;
  customClass?: string;
  handleClick?: (id: number) => void;
}

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const SingleCard: FC<ICard> = ({
  cardHolder,
  cardNumber,
  icon,
  id,
  isHaveCheckMark,
  isChecked,
  customClass = "",
  handleClick,
}) => {
  const dispatch = useAppDispatch();

  const maskedCard = maskCardNumber(cardNumber);

  const handleOpenDeleteModal = () => {
    dispatch(setShowDeleteCardModal(true));
    dispatch(setSelectedCardId(id));
  };

  return (
    <div
      className={`single_card_section ${customClass}`}
      onClick={() => (handleClick ? handleClick(id) : {})}
    >
      <div className="card_info">
        <img
          src={`${imageBase}${icon}`}
          alt="Visa"
          className="card_icon"
          crossOrigin="anonymous"
        />
        <div className="card_data">
          <p
            className="card_text"
            data-tooltip-id="card_name"
            data-tooltip-content={cardHolder}
          >
            {cardHolder}
          </p>
          <p className="card_text">{maskedCard}</p>
          <Tooltip id="card_name" className="custom_tooltip" />
        </div>
      </div>
      {isHaveCheckMark ? (
        <div className="check_icon_field">
          <img
            src={isChecked ? checkedIcon : unCheckedIcon}
            alt="Checked"
            className="check_icon"
          />
        </div>
      ) : (
        <div className="delete_icon_field" onClick={handleOpenDeleteModal}>
          <img src={deleteIcon} alt="Delete" className="delete_icon" />
          <img
            src={deleteActiveIcon}
            alt="Delete"
            className="delete_icon_active"
          />
        </div>
      )}
    </div>
  );
};
