import { FC, useState } from "react";

import { TableBody, TableFilters, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";
import { TRANSACTION_TABLE_HEADER } from "constants/table";
import { FORMAT_DATA } from "constants/global";

export const TransactionTable: FC<IMainTable> = () => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<ITableFilters>({});
  const [activeFormat, setActiveFormat] = useState<string>(
    FORMAT_DATA[0].value
  );

  const onTabChange = () => {
    setFilters({ ...filters, status: [] });
  };

  return (
    <div className="main_table_container padding_0">
      <div className="main_table_content">
        <TableHeader
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          setActiveFormat={setActiveFormat}
          activeFormat={activeFormat}
          onTabChange={onTabChange}
        />
        <div
          className={`filters_container ${
            openFilters ? "filter_container_show" : ""
          }`}
        >
          <TableFilters
            setFilters={setFilters}
            openFilters={openFilters}
            activeFormat={activeFormat}
          />
        </div>
        <TableBody
          headerData={TRANSACTION_TABLE_HEADER}
          filters={filters}
          activeFormat={activeFormat}
        />
      </div>
    </div>
  );
};
