import { IBanner } from "types";
import { FC } from "react";

export const AuthBanner: FC<IBanner> = ({ banner }) => {
  return (
    <div className="auth_banner">
      <img src={banner} alt="Banner" className="banner_image" />
    </div>
  );
};
