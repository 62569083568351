import { FC } from "react";

import { useTranslation } from "localization";

interface ILabel {
  text: string;
  type: string;
}

export const Label: FC<ILabel> = ({ text, type }) => {
  const translation = useTranslation();
  return (
    <div className={`label label_${type}`}>
      <div className={`circle circle_${type}`}></div>
      <div className="label_name">{translation[text]}</div>
    </div>
  );
};
