import { Dispatch, FC, SetStateAction } from "react";

interface ICheckbox {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

export const Checkbox: FC<ICheckbox> = ({
  checked,
  setChecked,
  id = "checkbox",
}) => {
  return (
    <div className="checkbox_container">
      <label
        htmlFor={id}
        className={`checkbox_label ${checked ? "checked_checkbox_label" : ""}`}
      ></label>
      <input
        type="checkbox"
        id={id}
        name={id}
        className="checkbox_input"
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
      />
    </div>
  );
};
