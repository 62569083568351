import { useTranslation } from "localization";
import { InfoElement } from "./InfoElement";
import { useAppSelector } from "reduxState/store";
import { formatDate } from "helpers";
import { IN_OUT_COME, IN_OUT_COME_NAME } from "constants/table";

import withdrawIcon from "assets/images/balance/withdraw.svg";

export const MobileTable = () => {
  const translation = useTranslation();

  const data = useAppSelector((state) => state.userData.balanceHistory);

  return (
    <div className="mobile_table_container" id="mobile_table_container">
      {data?.map((balance, index) => (
        <div className="trade_mobile_section" key={index}>
          <div className="trade_information">
            <div className="trade_elements_title">
              <InfoElement
                title={translation.crypto_coin}
                name={`${balance?.amount?.toFixed(2)}`}
              />
              <InfoElement
                title={translation.source}
                name={balance?.type}
                icon={withdrawIcon}
              />
              <InfoElement
                title={translation.type}
                name={translation[IN_OUT_COME_NAME[balance?.direction]]}
                icon={IN_OUT_COME[balance?.direction]}
              />
              <InfoElement
                title={translation.date}
                name={formatDate(balance?.date)}
              />
            </div>
          </div>
        </div>
      ))}
      <div></div>
    </div>
  );
};
