import { FC } from "react";

import { Label } from "components";
import { useTranslation } from "localization";

import amdCurrency from "assets/images/armenianCurrency.svg";

interface IInfoElement {
  title: string;
  name: string;
  isStatus?: boolean;
  isAction?: boolean;
  isIconEnd?: boolean;
  fullName?: string;
  icon?: string;
  handleViewDetails?: () => void;
  disabled?: boolean;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const InfoElement: FC<IInfoElement> = ({
  title,
  name,
  isStatus,
  isAction,
  isIconEnd,
  icon,
  fullName,
  handleViewDetails,
  disabled,
}) => {
  const translation = useTranslation();

  return (
    <div className="element_title_section">
      <div className="element_title">{title}</div>
      <div className="element_name_section">
        {isStatus ? (
          <Label type={name} text={translation[name]} />
        ) : isAction ? (
          <button
            className={`view_details ${disabled ? "disable_view_button" : ""}`}
            onClick={handleViewDetails}
            disabled={disabled}
          >
            {name}
          </button>
        ) : icon ? (
          <>
            <img
              src={`${imageUrl}${icon}`}
              alt="Currency"
              className="currency_icon"
              crossOrigin="anonymous"
            />
            <div className="currency_field">
              <span className="element_name">{name}</span>
              <span className="currency_line"></span>
              <span className="element_name">{fullName}</span>
            </div>
          </>
        ) : (
          <span className="element_name">{name}</span>
        )}
        {!!isIconEnd && (
          <img src={amdCurrency} alt="Currency" className="amd_currency_icon" />
        )}
      </div>
    </div>
  );
};
